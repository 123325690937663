export default {
  reviews: 'Customer reviews',
  your_rating: 'Your overall rating',
  based_on: 'Based on {num} reviews',
  responding_to_reviews_title: 'Responding to customer reviews',
  responding_to_reviews: 'See what a good reply looks like on our ',
  responding_to_reviews_start:
    'Responding to a review – whether it’s a good one or a bad one – shows your customers that you care about their experience. Because your response will be shown publicly, though, you should keep a few things in mind.',
  more_orders_more_reviews: 'More orders = more reviews',
  more_orders_more_reviews_text: `Try creating an offer with our marketing tools. Boosting sales will help you get your first review sooner!`,
  create_an_offer: 'Create an offer',
  read_more: 'handy help page.',
  view_breakdown: 'View rating breakdown',
  hide_breakdown: 'Hide rating breakdown',
  what_customers_think: 'What your customers think',
  monthly_data: 'Data based on {num} reviews in the last year',
  food_quality: 'Food/item quality',
  positive: 'Positive: {num}%',
  delivery_time: 'Delivery time',
  reviews_label: 'review | reviews',
  reviews_percent: '{num}% reviews',
  you_replied: 'You replied',
  pending_approval: 'Pending approval',
  your_response: 'Your response',
  send: 'Send',
  cancel: 'Cancel',
  reply: 'Reply',
  be_polite: 'Be polite',
  thank_customer:
    'Thank the customer for taking the time to leave the review. (Even if it’s negative.)',
  be_professional: 'Be professional',
  talk_about_issue:
    'Talk about the issues the customer is  upset about. Don’t ignore them or treat them as if they’re not important.',
  be_patient: 'Be patient',
  dont_be_defensive:
    'Don’t be defensive or get personal, even if the customer is rude. Potential customers can see your response, remember.',
  be_proactive: 'Be proactive',
  take_the_opportunity:
    'Take the opportunity to fix the customer’s problem. Offer to include a free item with their next order or say what you’ll change to make sure the problem won’t happen again.',
  this_will_make:
    'All this can make a customer more likely to order from you again. They might even tell their friends about the great service they’ve received.',
  all_reviews_checked:
    'All review responses are checked by us before being published. This can take up to 2 working days.',
  no_reviews: 'No reviews in the last year',
  you_dont_have: 'You don’t have any reviews',
  you_dont_have_variant: 'Hold tight! Your first review will be here soon.',
  try_to_motivate: 'As soon as a customer leaves a review, you’ll see it here.',
  try_to_motivate_variant: `We'll let you know when you've received your first review.`,
  order_n: 'Order #{num}',
  ratings_and_reviews: 'Ratings and reviews',
  neutral: 'Neutral: {num}%',
  negative: 'Negative: {num}%',
  back_to_top: 'Back to the top',
  copy_order_number: 'Copy order number',
  pricingandfees_1_restaurant: 'Fair price',
  pricingandfees_0_restaurant: 'Unfair price', // 0 == -1 from InsightsData
  wrongormissingitems_0_restaurant: 'Wrong or missing items',
  servicequality_1_restaurant: 'Great service',
  servicequality_0_restaurant: 'Bad service',
  foodquality_1_restaurant: 'Tasty food',
  foodquality_0_restaurant: 'Bad food',
  portionsize_1_restaurant: 'Great portions',
  portionsize_0_restaurant: 'Small portions',
  orderneverarrived_0_restaurant: 'Order never arrived',
  deliveryspeed_1_restaurant: 'Fast delivery',
  deliveryspeed_0_restaurant: 'Late delivery',
  deliveryservice_1_restaurant: 'Good delivery service',
  deliveryservice_0_restaurant: 'Bad delivery service',
  foodtemperature_1_restaurant: 'Perfect food temperature',
  foodtemperature_0_restaurant: 'Cold/Melted food',
  customersatisfaction_1_restaurant: 'Satisfied customer',
  customersatisfaction_0_restaurant: 'Unsatisfied customer',
  foodleakedorspilled_0_restaurant: 'Food leaked/spilled'
}
