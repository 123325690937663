<template>
  <div v-if="!isLoading">
    <div class="home">
      <div class="home__header">
        <h1 class="home__heading">{{ $t('ratings_and_reviews') }}</h1>
      </div>

      <div class="home__sidebar">
        <!-- show icon if no reviews -->
        <no-reviews-icon v-show="reviewsCount === 0" class="noreviews-img" />

        <!-- show rating if reviews exist -->
        <your-rating v-show="reviewsCount" />

        <!-- show "Customer Reviews" heading (mobile only) if reviews exist -->
        <div class="home__custreviews">
          <h1 class="home__custreviews__heading" v-show="reviewsCount > 0">
            {{ $t('reviews') }}
          </h1>
        </div>

        <!-- if 1-10 reviews show marketing link on the side -->
        <pie-notification
          v-show="reviewsCount <= 10 && reviewsCount > 0"
          isopen=""
          variant="info"
          position="inline-content"
          :heading="$t('more_orders_more_reviews')"
          headinglevel="h2"
          data-cy="moreOrdersNotification"
        >
          {{ $t('more_orders_more_reviews_text') }}
          <pie-link
            tag="a"
            variant="high-visibility"
            size="medium"
            isBold
            underline="reversed"
            isStandalone
            data-cy="moreOrdersLink"
            href="../marketing"
          >
            {{ $t('create_an_offer') }}
          </pie-link>
        </pie-notification>

        <!-- if > 10 reviews show reviewTip modal link -->
        <review-tip
          v-show="reviewsCount > 10"
          v-if="isReplyEnabled"
          @show="isShown = true"
        />
        <reviews-modal
          v-if="isReplyEnabled"
          @close="isShown = false"
          :isShown="isShown"
        />
      </div>

      <!-- main -->
      <div class="home__main">
        <reviews-list v-show="!isLoading" :is-reply-enabled="isReplyEnabled" />
      </div>
    </div>
  </div>
  <loader v-else />
</template>

<script>
import '../styles/typography.css'
import YourRating from '../components/YourRating'
import ReviewsList from '../components/ReviewsList'
import ReviewTip from '../components/ReviewTip'
import ReviewsModal from '../components/ReviewsModal'
import NoReviewsIcon from '@/icons/NoReviewsIcon.vue'
import Loader from '../components/Loader'
import { mapState, mapGetters } from 'vuex'
import { GET_REVIEWS, GET_REVIEW_AGGREGATIONS } from '@/store/actions.types'
import { getFeature } from '../services/featuremanagement'
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/notification.js'

export default {
  name: 'Home',
  components: {
    YourRating,
    ReviewsList,
    ReviewTip,
    ReviewsModal,
    Loader,
    NoReviewsIcon
  },
  data() {
    return {
      isShown: false,
      isLoading: false,
      isReplyEnabled: false,
      isAAExperimentEnabled: false
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch(GET_REVIEW_AGGREGATIONS)
    await this.$store.dispatch(GET_REVIEWS)
    this.initFeatureValue()
    this.initAAExperiment()
    this.isLoading = false
  },
  computed: {
    ...mapState(['reviews']),
    ...mapGetters(['reviewsCount'])
  },
  methods: {
    initFeatureValue() {
      getFeature('restaurant-replies', Boolean).then(result => {
        this.isReplyEnabled = result ?? false
      })
    },
    initAAExperiment() {
      getFeature('partner-reviews-aa-test-feature', Boolean).then(result => {
        this.isAAExperimentEnabled = result ?? false //test that AA Experiment is being invoked
      })
    }
  }
}
</script>

<style scoped>
.home {
  margin: 0px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'sidebar'
    'main';
  /* grid-gap: var(--spacing-e, 24px); gap doesn't work because we need different sized row gaps for the grid*/
}

.home__header {
  grid-area: header;
  width: 100%;
  padding: var(--dt-spacing-c, 12px) var(--dt-spacing-d, 16px);
}

.home__heading h1 {
  margin-bottom: var(--dt-spacing-b, 8px);
}

.home__sidebar {
  grid-area: sidebar;
  margin: 0px var(--dt-spacing-d, 16px) 0px var(--dt-spacing-d, 16px);
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-d, 16px);
}

.home__main {
  grid-area: main;
  margin: 8px 16px 24px 16px;
}

/* TODO: should the img be fluid between breakpoints or fixed? */
.noreviews-img {
  width: 212px;
  height: 212px;
}

.home__custreviews {
  margin-top: var(--dt-spacing-b, 8px);
}

/* 1024px (normal sized tablet device) is the switch point from mobile to desktop inline with the micro site menu change */
@media screen and (min-width: 1024px) {
  .home {
    grid-template-columns: 2fr 1.2fr;
    grid-gap: var(--dt-spacing-e, 24px);
    grid-template-areas:
      'header header'
      'main sidebar';
  }
  .home__header {
    grid-column: 1 / 3;
    padding: 40px 120px 0px;
  }

  .home__sidebar {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin: 0px 120px 0px 0px;
  }

  .home__main {
    grid-column: 1 / 2;
    grid-row: 2 / auto;
    margin: 0px 0px 0px 120px;
  }

  .home__custreviews {
    display: none;
  }

  .noreviews-img {
    width: 326px;
    height: 326px;
  }
}
</style>
